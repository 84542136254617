<template>
  <div class="flex-grow-1 w-100 h-100 d-flex container__sign-in">
    <div class="bg-composed-wrapper--content">
      <b-container fluid>
        <b-row class="container__sign-in--desktop">
          <div
            class="
              col-lg-6
              offset-1
              flex-column
              align-items-center
              justify-content-center
              text-center text-lg-left
              container__left
            "
          >
            <img
              src="@/assets/img/sign-in-logo.png"
              style="width: 125px; height: auto; margin-bottom: 3rem"
            />

            <h1
              class="
                display-2
                mt-1
                mb-5
                font-weight-bold
                text-white text-nowrap
              "
            >
              LOGIN สำหรับครูแดร์
            </h1>

            <b-row>
              <b-col cols="12" lg="8" xl="6">
                <form class="pt-3">
                  <b-form-group class="mb-5 input__sign-in">
                    <b-input-group class="input-group-seamless">
                      <b-form-input
                        class="input__sign-in"
                        name="tel"
                        placeholder="กรอกเบอร์โทรศัพท์ที่ลงทะเบียน"
                        size="lg"
                        v-model="form.tel"
                        type="tel"
                        v-mask="'0##-###-####'"
                      ></b-form-input>

                      <b-input-group-text slot="prepend">
                        <img
                          src="@/assets/img/sign-in-icon.png"
                          style="width: 30px; height: auto; margin-bottom: 1rem"
                        />
                      </b-input-group-text>

                      <!-- <b-input-group-text v-show="keyword.length" slot="append">
                      <a href="javascript:void(0)" @click="clearKeyword">
                        <font-awesome-icon class="mx-auto" icon="window-close" />
                      </a>
                    </b-input-group-text> -->
                    </b-input-group>
                  </b-form-group>

                  <div class="pt-3">
                    <button
                      class="btn btn-light btn-block rounded-pill"
                      :disabled="checkingSignIn || otpTimeout > 0"
                      @click.prevent="signIn"
                    >
                      <b-spinner
                        v-if="checkingSignIn"
                        label="Loading..."
                      ></b-spinner>
                      <h5 v-else class="mb-0">
                        {{
                          otpTimeout > 0
                            ? `กรุณารออีก ${otpTimeout} วินาที เพื่อขอ OTP เข้าสู่ระบบใหม่อีกครั้ง`
                            : "เข้าสู่ระบบ"
                        }}
                      </h5>
                    </button>
                  </div>
                </form>

                <!-- <b-row align-v="center" class="pt-3">
                  <b-col cols="5" class="pr-0">
                    <hr class="hr__sign-in" />
                  </b-col>
                  <b-col cols="2" class="px-0 text-center text-white">
                    หรือ
                  </b-col>
                  <b-col cols="5" class="pl-0">
                    <hr class="hr__sign-in" />
                  </b-col>
                </b-row> -->

                <b-modal
                  v-model="showModalOTP"
                  id="otp-modal"
                  centered
                  hide-footer
                  hide-header
                >
                  <!-- <div slot="modal-header">
                <h6 class="display-4 font-weight-bold">Search</h6>
                <p class="text-black-50 mb-0">
                  Use the form below to search for files
                </p>
              </div> -->
                  <b-container>
                    <b-row>
                      <b-col>
                        <h5 class="display-3 text-primary font-weight-bold">
                          กรอกรหัส OTP
                        </h5>

                        <p class="text-black-50">
                          รหัส OTP ได้ถูกส่งไปยังเบอร์โทรศัพท์หมายเลข
                          {{ form.tel }}
                        </p>

                        <form>
                          <div class="form-group">
                            <label for="otp_code">รหัส OTP</label>
                            <input
                              type="text"
                              class="form-control"
                              name="otp_code"
                              v-model="form.otp_code"
                            />
                          </div>

                          <button
                            class="btn btn-lg btn-primary btn-block"
                            :disabled="checkingOTP"
                            @click.prevent="validateOTP"
                          >
                            <b-spinner
                              v-if="checkingOTP"
                              label="Loading..."
                            ></b-spinner>
                            <span v-else>ยืนยัน</span>
                          </button>
                        </form>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-modal>

                <!-- <button
                  class="btn rounded-pill btn-outline-light btn-block mt-3"
                  @click="showModalDoc = true"
                >
                  ดูคู่มือการใช้งาน D.A.R.E.
                </button>

                <router-link active-class="active" to="/registers" exact>
                  <b-button
                    variant="outline-light"
                    block
                    class="btn-white rounded-pill mt-3"
                  >
                    สมัครสมาชิกชมรม D.A.R.E. ประเทศไทย
                  </b-button>
                </router-link> -->

                <b-row class="mt-3 text-center">
                  <b-col class="text-white">
                    แจ้งปัญหา/ขอความช่วยเหลือเกี่ยวกับระบบได้ที่
                    <a
                      target="_blank"
                      href="http://line.me/ti/p/@858xnebk"
                      class="text-white"
                      style="text-decoration: underline"
                      >http://line.me/ti/p/@858xnebk</a
                    >
                  </b-col>
                </b-row>

                <b-modal
                  v-model="showModalDoc"
                  id="doc-modal"
                  size="xl"
                  centered
                  hide-footer
                  hide-header
                >
                  <!-- <div slot="modal-header">
                <h6 class="display-4 font-weight-bold">Search</h6>
                <p class="text-black-50 mb-0">
                  Use the form below to search for files
                </p>
              </div> -->
                  <b-container>
                    <b-row class="flex-sm-column-reverse flex-md-row">
                      <b-col
                        lg="5"
                        class="d-flex flex-column justify-content-between"
                      >
                        <h5
                          class="
                            display-3
                            mt-sm-4 mt-md-2
                            mb-3
                            text-primary
                            font-weight-bold
                          "
                        >
                          คู่มือการใช้งานระบบฐานข้อมูล D.A.R.E.
                        </h5>

                        <div>
                          <h5
                            class="display-5 mb-2 text-primary font-weight-bold"
                          >
                            ดาวน์โหลดเอกสาร
                          </h5>

                          <div>
                            <u>
                              <a
                                href="https://drive.google.com/file/d/1dN0fQpS5XgoTFVNIP-9_lswylqEut-4Z/view"
                                target="_blank"
                                >ไฟล์เอกสาร PDF</a
                              >
                            </u>
                          </div>

                          <!-- <div>
                        <u>
                          <a href="http://" target="_blank"
                            >ไฟล์เอกสาร PowerPoint</a
                          >
                        </u>
                      </div> -->
                        </div>
                      </b-col>
                      <b-col lg="7" class="mt-md-2">
                        <div class="video-container">
                          <!-- <iframe
                        width="1537"
                        height="872"
                        src="https://www.youtube.com/embed/RmuL-BPFi2Q?list=RDRmuL-BPFi2Q"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe> -->
                          <b-embed
                            src="https://www.youtube.com/embed/vmd2KaTElDE"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          >
                          </b-embed>
                        </div>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-modal>
              </b-col>
            </b-row>

            <!-- <h3 class="display-1 mt-1 mb-2 text-first font-weight-bold">
              D.A.R.E. Thailand
            </h3> -->

            <!-- <img
              src="@/assets/img/login-new.jpg"
              class="img-fluid d-none d-lg-flex"
            /> -->

            <!-- <LoginSvg class="mx-auto d-block img-fluid" /> -->
          </div>

          <!-- <div
            class="
              col-lg-4
              offset-1
              flex-column
              align-items-center
              justify-content-center
              text-center text-lg-left
              container__right
            "
          >
            <div class="d-flex align-items-baseline">
              <h3
                class="
                  display-3
                  font-weight-bold
                  text-sign-in--primary
                  text-nowrap
                "
              >
                เข้าสู่เว็ปไซต์
              </h3>
              <h5 class="display-4 font-weight-bold text-sign-in--primary pl-2">
                สำหรับนักเรียน
              </h5>
            </div>

            <div class="d-flex align-items-baseline">
              <font-awesome-icon
                icon="angle-right"
                size="lg"
                class="position-relative text-sign-in--secondary mx-2"
                style="top: 3px"
              />

              <h4 class="font-weight-bold text-sign-in--secondary">
                หลักสูตร D.A.R.E.
              </h4>
            </div>

            <b-row>
              <b-col lg="10" xl="8">
                <a href="https://e-learning.xn--l3cgao1bcb5b5d8hf8b6f.com">
                  <b-button
                    variant="danger"
                    block
                    pill
                    class="btn-e-learning mt-3"
                  >
                    <h5 class="mb-0">เริ่มเรียนกันเลย!!!</h5>
                  </b-button>
                </a>
              </b-col>
            </b-row>
          </div> -->

          <img class="img__art-1" src="@/assets/img/sign-in-art-1.png" />

          <!-- <div
            class="
              col-10
              offset-1
              col-lg-4
              offset-lg-0
              pr-0
              d-flex
              flex-column
              justify-content-center
            "
          >
            <b-card class="text-black mt-3 my-4">
              <LogoSvg class="w-50 mx-auto d-block img-fluid mb-3" />

              <h1 class="display-3 mb-3 text-primary font-weight-bold">
                เข้าสู่ระบบ
              </h1>

              <div>
                <form>
                  <div class="form-group">
                    <label for="tel">เบอร์โทรศัพท์</label>
                    <input
                      type="tel"
                      class="form-control"
                      name="tel"
                      placeholder="0xx-xxx-xxxx"
                      v-mask="'0##-###-####'"
                      v-model="form.tel"
                    />
                  </div>

                  <button
                    class="btn btn-lg btn-primary btn-block"
                    :disabled="checkingSignIn || otpTimeout > 0"
                    @click.prevent="signIn"
                  >
                    <b-spinner
                      v-if="checkingSignIn"
                      label="Loading..."
                    ></b-spinner>
                    <span v-else>
                      {{
                        otpTimeout > 0
                          ? `กรุณารออีก ${otpTimeout} วินาที เพื่อขอ OTP เข้าสู่ระบบใหม่อีกครั้ง`
                          : "เข้าสู่ระบบ"
                      }}
                    </span>
                  </button>
                </form>
              </div>
            </b-card>

            <b-modal
              v-model="showModalOTP"
              id="otp-modal"
              centered
              hide-footer
              hide-header
            >
              <b-container>
                <b-row>
                  <b-col>
                    <h5 class="display-3 text-primary font-weight-bold">
                      กรอกรหัส OTP
                    </h5>

                    <p class="text-black-50">
                      รหัส OTP ได้ถูกส่งไปยังเบอร์โทรศัพท์หมายเลข {{ form.tel }}
                    </p>

                    <form>
                      <div class="form-group">
                        <label for="otp_code">รหัส OTP</label>
                        <input
                          type="text"
                          class="form-control"
                          name="otp_code"
                          v-model="form.otp_code"
                        />
                      </div>

                      <button
                        class="btn btn-lg btn-primary btn-block"
                        :disabled="checkingOTP"
                        @click.prevent="validateOTP"
                      >
                        <b-spinner
                          v-if="checkingOTP"
                          label="Loading..."
                        ></b-spinner>
                        <span v-else>ยืนยัน</span>
                      </button>
                    </form>
                  </b-col>
                </b-row>
              </b-container>
            </b-modal>

            <button
              class="btn btn-lg btn-danger btn-block"
              @click="showModalDoc = true"
            >
              ดูคู่มือการใช้งาน D.A.R.E.
            </button>

            <router-link
              class="mt-3"
              active-class="active"
              to="/registers"
              exact
            >
              <b-button variant="outline-danger" block>
                สมัครสมาชิกชมรม D.A.R.E. ประเทศไทย
              </b-button>
            </router-link>

            <b-row class="mt-3 text-center">
              <b-col>
                แจ้งปัญหา/ขอความช่วยเหลือเกี่ยวกับระบบได้ที่
                <a target="_blank" href="http://line.me/ti/p/@858xnebk"
                  >http://line.me/ti/p/@858xnebk</a
                >
              </b-col>
            </b-row>

            <b-modal
              v-model="showModalDoc"
              id="doc-modal"
              size="xl"
              centered
              hide-footer
              hide-header
            >
              <b-container>
                <b-row class="flex-sm-column-reverse flex-md-row">
                  <b-col
                    lg="5"
                    class="d-flex flex-column justify-content-between"
                  >
                    <h5
                      class="
                        display-3
                        mt-sm-4 mt-md-2
                        mb-3
                        text-primary
                        font-weight-bold
                      "
                    >
                      คู่มือการใช้งานระบบฐานข้อมูล D.A.R.E.
                    </h5>

                    <div>
                      <h5 class="display-5 mb-2 text-primary font-weight-bold">
                        ดาวน์โหลดเอกสาร
                      </h5>

                      <div>
                        <u>
                          <a
                            href="https://drive.google.com/file/d/1dN0fQpS5XgoTFVNIP-9_lswylqEut-4Z/view"
                            target="_blank"
                            >ไฟล์เอกสาร PDF</a
                          >
                        </u>
                      </div>
                    </div>
                  </b-col>
                  <b-col lg="7" class="mt-md-2">
                    <div class="video-container">
                      <b-embed
                        src="https://www.youtube.com/embed/vmd2KaTElDE"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      >
                      </b-embed>
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </b-modal>
          </div> -->
        </b-row>
        <div class="row container__sign-in--mobile">
          <b-container class="h-100">
            <b-row align-h="center" align-v="stretch" style="height: 80%">
              <div
                class="
                  col-12
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-start justify-content-sm-center
                  text-center text-lg-left
                  container__left
                "
              >
                <img
                  src="@/assets/img/sign-in-logo.png"
                  style="width: 125px; height: auto; margin-bottom: 0.5rem"
                />

                <div
                  class="
                    d-flex
                    align-items-baseline
                    justify-content-center
                    mb-2
                  "
                >
                  <h1
                    class="
                      display-3
                      mt-1
                      mr-1
                      font-weight-bold
                      text-sign-in--secondary
                      text-nowrap
                    "
                  >
                    LOGIN
                  </h1>

                  <h3 class="text-sign-in--primary">สำหรับครูแดร์</h3>
                </div>

                <b-container>
                  <form>
                    <b-form-group class="input__sign-in">
                      <b-input-group class="input-group-seamless">
                        <b-form-input
                          class="input__sign-in"
                          name="tel"
                          placeholder="กรอกเบอร์โทรศัพท์ที่ลงทะเบียน"
                          v-model="form.tel"
                          type="tel"
                          v-mask="'0##-###-####'"
                        ></b-form-input>

                        <b-input-group-text slot="prepend">
                          <img
                            src="@/assets/img/sign-in-icon.png"
                            style="
                              width: 30px;
                              height: auto;
                              margin-bottom: 1rem;
                            "
                          />
                        </b-input-group-text>

                        <!-- <b-input-group-text v-show="keyword.length" slot="append">
                        <a href="javascript:void(0)" @click="clearKeyword">
                          <font-awesome-icon class="mx-auto" icon="window-close" />
                        </a>
                      </b-input-group-text> -->
                      </b-input-group>
                    </b-form-group>

                    <div>
                      <button
                        class="
                          btn btn-sm
                          btn-sign-in--primary
                          btn-block
                          rounded-pill
                        "
                        :disabled="checkingSignIn || otpTimeout > 0"
                        @click.prevent="signIn"
                      >
                        <b-spinner
                          v-if="checkingSignIn"
                          label="Loading..."
                        ></b-spinner>
                        <span v-else class="mb-0">
                          {{
                            otpTimeout > 0
                              ? `กรุณารออีก ${otpTimeout} วินาที เพื่อขอ OTP เข้าสู่ระบบใหม่อีกครั้ง`
                              : "เข้าสู่ระบบ"
                          }}
                        </span>
                      </button>
                    </div>
                  </form>

                  <!-- <b-row align-v="center" class="pt-2">
                    <b-col cols="5" class="pr-0">
                      <hr class="hr__sign-in" />
                    </b-col>
                    <b-col
                      cols="2"
                      class="px-0 text-center text-sign-in--primary"
                    >
                      หรือ
                    </b-col>
                    <b-col cols="5" class="pl-0">
                      <hr class="hr__sign-in" />
                    </b-col>
                  </b-row> -->

                  <b-modal
                    v-model="showModalOTP"
                    id="otp-modal"
                    centered
                    hide-footer
                    hide-header
                  >
                    <!-- <div slot="modal-header">
                  <h6 class="display-4 font-weight-bold">Search</h6>
                  <p class="text-black-50 mb-0">
                    Use the form below to search for files
                  </p>
                </div> -->
                    <b-container>
                      <b-row>
                        <b-col>
                          <h5 class="display-3 text-primary font-weight-bold">
                            กรอกรหัส OTP
                          </h5>

                          <p class="text-black-50">
                            รหัส OTP ได้ถูกส่งไปยังเบอร์โทรศัพท์หมายเลข
                            {{ form.tel }}
                          </p>

                          <form>
                            <div class="form-group">
                              <label for="otp_code">รหัส OTP</label>
                              <input
                                type="text"
                                class="form-control"
                                name="otp_code"
                                v-model="form.otp_code"
                              />
                            </div>

                            <button
                              class="btn btn-lg btn-primary btn-block"
                              :disabled="checkingOTP"
                              @click.prevent="validateOTP"
                            >
                              <b-spinner
                                v-if="checkingOTP"
                                label="Loading..."
                              ></b-spinner>
                              <span v-else>ยืนยัน</span>
                            </button>
                          </form>
                        </b-col>
                      </b-row>
                    </b-container>
                  </b-modal>

                  <!-- <button
                    class="
                      btn btn-sm
                      rounded-pill
                      btn-outline-sign-in--primary
                      btn-block
                      mt-2
                    "
                    @click="showModalDoc = true"
                  >
                    ดูคู่มือการใช้งาน D.A.R.E.
                  </button>

                  <router-link active-class="active" to="/registers" exact>
                    <b-button
                      variant="outline-sign-in--primary"
                      size="sm"
                      block
                      class="rounded-pill mt-3"
                    >
                      สมัครสมาชิกชมรม D.A.R.E. ประเทศไทย
                    </b-button>
                  </router-link> -->

                  <b-row class="mt-2 text-center">
                    <b-col class="text-dark">
                      แจ้งปัญหา/ขอความช่วยเหลือเกี่ยวกับระบบได้ที่
                      <a
                        target="_blank"
                        href="http://line.me/ti/p/@858xnebk"
                        class="text-first"
                        style="text-decoration: underline"
                        >http://line.me/ti/p/@858xnebk</a
                      >
                    </b-col>
                  </b-row>

                  <b-modal
                    v-model="showModalDoc"
                    id="doc-modal"
                    size="xl"
                    centered
                    hide-footer
                    hide-header
                  >
                    <!-- <div slot="modal-header">
                  <h6 class="display-4 font-weight-bold">Search</h6>
                  <p class="text-black-50 mb-0">
                    Use the form below to search for files
                  </p>
                </div> -->
                    <b-container>
                      <b-row class="flex-sm-column-reverse flex-md-row">
                        <b-col
                          lg="5"
                          class="d-flex flex-column justify-content-between"
                        >
                          <h5
                            class="
                              display-3
                              mt-sm-4 mt-md-2
                              mb-3
                              text-primary
                              font-weight-bold
                            "
                          >
                            คู่มือการใช้งานระบบฐานข้อมูล D.A.R.E.
                          </h5>

                          <div>
                            <h5
                              class="
                                display-5
                                mb-2
                                text-primary
                                font-weight-bold
                              "
                            >
                              ดาวน์โหลดเอกสาร
                            </h5>

                            <div>
                              <u>
                                <a
                                  href="https://drive.google.com/file/d/1dN0fQpS5XgoTFVNIP-9_lswylqEut-4Z/view"
                                  target="_blank"
                                  >ไฟล์เอกสาร PDF</a
                                >
                              </u>
                            </div>

                            <!-- <div>
                          <u>
                            <a href="http://" target="_blank"
                              >ไฟล์เอกสาร PowerPoint</a
                            >
                          </u>
                        </div> -->
                          </div>
                        </b-col>
                        <b-col lg="7" class="mt-md-2">
                          <div class="video-container">
                            <!-- <iframe
                          width="1537"
                          height="872"
                          src="https://www.youtube.com/embed/RmuL-BPFi2Q?list=RDRmuL-BPFi2Q"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe> -->
                            <b-embed
                              src="https://www.youtube.com/embed/vmd2KaTElDE"
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen
                            >
                            </b-embed>
                          </div>
                        </b-col>
                      </b-row>
                    </b-container>
                  </b-modal>
                </b-container>

                <!-- <h3 class="display-1 mt-1 mb-2 text-first font-weight-bold">
                D.A.R.E. Thailand
              </h3> -->

                <!-- <img
                src="@/assets/img/login-new.jpg"
                class="img-fluid d-none d-lg-flex"
              /> -->

                <!-- <LoginSvg class="mx-auto d-block img-fluid" /> -->
              </div>

              <!-- <div
                class="
                  col-12
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-start
                  text-center text-lg-left
                  container__right
                "
              >
                <div class="d-flex flex-column align-items-baseline mx-auto">
                  <div class="d-flex align-items-baseline">
                    <h5
                      class="font-weight-bold text-white text-nowrap"
                      style="font-size: 2rem"
                    >
                      เข้าสู่เว็ปไซต์
                    </h5>

                    <h6 class="display-4 text-white pl-2">สำหรับนักเรียน</h6>
                  </div>

                  <div class="d-flex align-items-baseline">
                    <font-awesome-icon
                      icon="angle-right"
                      size="lg"
                      class="position-relative text-white mx-2"
                      style="top: 3px"
                    />

                    <h4 class="text-white">หลักสูตร D.A.R.E.</h4>
                  </div>
                </div>

                <a
                  href="https://e-learning.xn--l3cgao1bcb5b5d8hf8b6f.com"
                >
                  <b-button
                    variant="danger"
                    block
                    pill
                    class="btn-e-learning mt-3"
                  >
                    <h5 class="mb-0">เริ่มเรียนกันเลย!!!</h5>
                  </b-button>
                </a>

                <img class="img__art-1" src="@/assets/img/sign-in-art-1.png" />
              </div> -->
            </b-row>
          </b-container>

          <!-- <div
            class="
              col-10
              offset-1
              col-lg-4
              offset-lg-0
              pr-0
              d-flex
              flex-column
              justify-content-center
            "
          >
            <b-card class="text-black mt-3 my-4">
              <LogoSvg class="w-50 mx-auto d-block img-fluid mb-3" />

              <h1 class="display-3 mb-3 text-primary font-weight-bold">
                เข้าสู่ระบบ
              </h1>

              <div>
                <form>
                  <div class="form-group">
                    <label for="tel">เบอร์โทรศัพท์</label>
                    <input
                      type="tel"
                      class="form-control"
                      name="tel"
                      placeholder="0xx-xxx-xxxx"
                      v-mask="'0##-###-####'"
                      v-model="form.tel"
                    />
                  </div>

                  <button
                    class="btn btn-lg btn-primary btn-block"
                    :disabled="checkingSignIn || otpTimeout > 0"
                    @click.prevent="signIn"
                  >
                    <b-spinner
                      v-if="checkingSignIn"
                      label="Loading..."
                    ></b-spinner>
                    <span v-else>
                      {{
                        otpTimeout > 0
                          ? `กรุณารออีก ${otpTimeout} วินาที เพื่อขอ OTP เข้าสู่ระบบใหม่อีกครั้ง`
                          : "เข้าสู่ระบบ"
                      }}
                    </span>
                  </button>
                </form>
              </div>
            </b-card>

            <b-modal
              v-model="showModalOTP"
              id="otp-modal"
              centered
              hide-footer
              hide-header
            >
              <b-container>
                <b-row>
                  <b-col>
                    <h5 class="display-3 text-primary font-weight-bold">
                      กรอกรหัส OTP
                    </h5>

                    <p class="text-black-50">
                      รหัส OTP ได้ถูกส่งไปยังเบอร์โทรศัพท์หมายเลข {{ form.tel }}
                    </p>

                    <form>
                      <div class="form-group">
                        <label for="otp_code">รหัส OTP</label>
                        <input
                          type="text"
                          class="form-control"
                          name="otp_code"
                          v-model="form.otp_code"
                        />
                      </div>

                      <button
                        class="btn btn-lg btn-primary btn-block"
                        :disabled="checkingOTP"
                        @click.prevent="validateOTP"
                      >
                        <b-spinner
                          v-if="checkingOTP"
                          label="Loading..."
                        ></b-spinner>
                        <span v-else>ยืนยัน</span>
                      </button>
                    </form>
                  </b-col>
                </b-row>
              </b-container>
            </b-modal>

            <button
              class="btn btn-lg btn-danger btn-block"
              @click="showModalDoc = true"
            >
              ดูคู่มือการใช้งาน D.A.R.E.
            </button>

            <router-link
              class="mt-3"
              active-class="active"
              to="/registers"
              exact
            >
              <b-button variant="outline-danger" block>
                สมัครสมาชิกชมรม D.A.R.E. ประเทศไทย
              </b-button>
            </router-link>

            <b-row class="mt-3 text-center">
              <b-col>
                แจ้งปัญหา/ขอความช่วยเหลือเกี่ยวกับระบบได้ที่
                <a target="_blank" href="http://line.me/ti/p/@858xnebk"
                  >http://line.me/ti/p/@858xnebk</a
                >
              </b-col>
            </b-row>

            <b-modal
              v-model="showModalDoc"
              id="doc-modal"
              size="xl"
              centered
              hide-footer
              hide-header
            >
              <b-container>
                <b-row class="flex-sm-column-reverse flex-md-row">
                  <b-col
                    lg="5"
                    class="d-flex flex-column justify-content-between"
                  >
                    <h5
                      class="
                        display-3
                        mt-sm-4 mt-md-2
                        mb-3
                        text-primary
                        font-weight-bold
                      "
                    >
                      คู่มือการใช้งานระบบฐานข้อมูล D.A.R.E.
                    </h5>

                    <div>
                      <h5 class="display-5 mb-2 text-primary font-weight-bold">
                        ดาวน์โหลดเอกสาร
                      </h5>

                      <div>
                        <u>
                          <a
                            href="https://drive.google.com/file/d/1dN0fQpS5XgoTFVNIP-9_lswylqEut-4Z/view"
                            target="_blank"
                            >ไฟล์เอกสาร PDF</a
                          >
                        </u>
                      </div>
                    </div>
                  </b-col>
                  <b-col lg="7" class="mt-md-2">
                    <div class="video-container">
                      <b-embed
                        src="https://www.youtube.com/embed/vmd2KaTElDE"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      >
                      </b-embed>
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </b-modal>
          </div> -->
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
// import LogoSvg from "../../assets/img/logo.svg";
import { Auth } from "../../models";

export default {
  // components: {
  //   LogoSvg,
  // },

  data() {
    return {
      showModalDoc: false,
      showModalOTP: false,
      checkingSignIn: false,
      checkingOTP: false,
      otpTimeout: 0,
      optInterval: null,
      form: {
        tel: "",
        authToken: "",
        token: "",
        user_profiles: null,
        otp_code: "",
      },
    };
  },

  watch: {
    otpTimeout: "onOtpTimeoutChanged",
  },

  methods: {
    async signIn() {
      this.checkingSignIn = true;

      let { tel } = this.form;

      tel = tel.replace(/-/g, "");

      try {
        const { user_profiles, token: authToken } = await Auth.api().signIn(
          tel
        );

        this.$set(this.form, "authToken", authToken);
        this.$set(this.form, "user_profiles", user_profiles);

        const token = await Auth.api().getOTP(tel);

        this.$set(this.form, "token", token);

        this.setOtpTimeout();

        this.showModalOTP = true;
      } catch (message) {
        this.$toast.error(message);
      } finally {
        this.checkingSignIn = false;
      }
    },

    setOtpTimeout() {
      this.otpTimeout = 30;
    },

    onOtpTimeoutChanged(v) {
      if (v > 0 && !this.optInterval) {
        this.optInterval = setInterval(() => {
          this.otpTimeout -= 1;
        }, 1000);
      } else {
        if (v === 0) {
          clearInterval(this.optInterval);
          this.optInterval = null;
        }
      }
    },

    async validateOTP() {
      this.checkingOTP = true;
      try {
        if (!this.form.otp_code.length) {
          throw "กรุณากรอกรหัส OTP";
        }

        const valid = await Auth.api().validateOTP(
          this.form.token,
          this.form.otp_code
        );

        if (valid) {
          Auth.setAuthToken(this.form.authToken);
          Auth.setAuthId(this.form.user_profiles.id);

          this.showModalOTP = false;

          this.$toast.success(
            "เข้าสู่ระบบสำเร็จ ระบบจะเข้าสู้หน้าหลักในอีกสักครู่"
          );

          setTimeout(() => {
            location.href =
              decodeURIComponent(this.$route.query.redirectUrl) || "/";
          }, 1000);
        }
      } catch (message) {
        console.log(message);
        this.$toast.error(message);
      } finally {
        this.checkingOTP = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input__sign-in {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-radius: 0 !important;
  background-color: transparent;
  caret-color: white;
  color: white;
}

.hr__sign-in {
  border-top: 1px solid rgba(255, 255, 255, 1);
}

.text-sign-in--primary {
  color: rgba(8, 59, 160, 1);
}

.text-sign-in--secondary {
  color: rgba(238, 0, 0, 1);
}

.container__right {
  margin-top: 7.5rem;
}

.btn-sign-in--primary {
  color: #fff;
  background-color: rgba(8, 59, 160, 1);
  border-color: rgba(8, 59, 160, 1);
}

.btn-sign-in--primary:not(:disabled):not(.disabled):active,
.btn-sign-in--primary:not(:disabled):not(.disabled).active,
.show > .btn-sign-in--primary.dropdown-toggle {
  color: #fff;
  background-color: rgba(8, 59, 160, 1);
  border-color: rgb(18, 58, 138);
}

.btn-outline-sign-in--primary {
  color: rgba(8, 59, 160, 1);
  background-color: #fff;
  border-color: rgba(8, 59, 160, 1);
}

.btn-outline-sign-in--primary:not(:disabled):not(.disabled):active,
.btn-outline-sign-in--primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-sign-in--primary.dropdown-toggle {
  color: #fff;
  background-color: rgba(8, 59, 160, 1);
  border-color: rgb(18, 58, 138);
}

.btn-e-learning {
  color: #fff;
  background-color: rgba(238, 0, 0, 1);
  border-color: rgba(238, 0, 0, 1);
}

.btn-e-learning:not(:disabled):not(.disabled):active,
.btn-e-learning:not(:disabled):not(.disabled).active,
.show > .btn-e-learning.dropdown-toggle {
  color: #fff;
  background-color: rgba(238, 0, 0, 1);
  border-color: rgb(212, 19, 19);
}

@media (max-width: 991.98px) {
  .input__sign-in {
    caret-color: #7a7b97;
    color: #7a7b97;
  }

  .hr__sign-in {
    border-top: 1px solid rgba(8, 59, 160, 1);
  }

  .container__right {
    margin-top: 0rem !important;
    // padding-bottom: 250px;
  }
}
</style>

<style lang="scss">
.app-header {
  display: none;
}

@media (max-width: 991.98px) {
  .bg-composed-wrapper--content {
    padding-top: 2rem !important;
  }
}
</style>
